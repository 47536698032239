import React, {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {FloorplanSection, trackContactClicked} from "@renaissancerentals/renaissance-component";
import {MainLayout} from "../components/layout/MainLayout";
import {PageProps} from "./Home";
import {NotFound} from "./NotFound";

export const FloorplanPage: React.FC<PageProps> = ({property, contact, isLoading}) => {
    const floorplanParam = useParams();
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);

    const mapRef: React.Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    return (
        <MainLayout isMainPage={false}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    handleRefToMap={handleRefToMap}
                    setShowApplicationModal={setShowApplicationModal} setShowContactModal={setShowContactModal}
                    contact={contact} property={property}>
            {isLoading ? <></> :
                floorplanParam.floorplanId ?
                    <FloorplanSection
                        contactClickHandler={() => {
                            setShowContactModal(true);
                            trackContactClicked("scholars-quad");
                        }}
                        applyClickHandler={() => setShowApplicationModal(true)}
                        floorplanId={floorplanParam.floorplanId} locationRef={mapRef}
                        handleRefToLocation={handleRefToMap}/> :
                    <NotFound/>
            }

        </MainLayout>
    );
}
