import React, {useRef, useState} from "react";
import {
    Contact,
    convertToFloorplanCardData,
    DriveGallery,
    FloorplanSectionSkeleton,
    FloorplansSection, HomePageSpecialSection,
    PropertyDetails,
    sortFloorplans,
    TeamSection,
    trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {HeroSection} from "../components/hero/HeroSection";
import {ActionSection} from "../components/action/ActionSection";
import {AmenitiesSection} from "../components/amenities/AmenitiesSection";
import {FeatureSection} from "../components/feature/FeatureSection";
import {PropertyLocation} from "@renaissancerentals/renaissance-component/lib/property/PropertyLocation";
import {MainLayout} from "../components/layout/MainLayout";
import {propertyId} from "../services/DataService";
import {Helmet} from "react-helmet-async";

export const Home: React.FC<PageProps> = ({property, isLoading, contact}) => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorPlanRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    const handleRefToFloorPlan = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const test = "<!-- Google tag (gtag.js) -->\n" +
        "<script async src=\"https://www.googletagmanager.com/gtag/js?id=AW-970718846\"></script>\n" +
        "<script>\n" +
        "  window.dataLayer = window.dataLayer || [];\n" +
        "  function gtag(){dataLayer.push(arguments);}\n" +
        "  gtag('js', new Date());\n" +
        "\n" +
        "  gtag('config', 'AW-970718846');\n" +
        "</script>";
    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {test}
                </script>
            </Helmet>
            <MainLayout handleRefToFloorPlan={handleRefToFloorPlan}
                        handleRefToMap={handleRefToMap} isMainPage={true}
                        showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                        setShowApplicationModal={setShowApplicationModal}
                        setShowContactModal={setShowContactModal}
                        contact={contact} property={property}
            >
                <HomePageSpecialSection propertyId={property.id}/>
                <HeroSection handleRefToFloorPlan={handleRefToFloorPlan}/>
                <div className="glasses-background">
                    <FeatureSection/>
                    <ActionSection handleApplyClicked={() => setShowApplicationModal(true)}
                                   handleContactClicked={() => {
                                       setShowContactModal(true);
                                       trackContactClicked("scholars-quad");
                                   }}
                                   handleRefToFloorPlan={handleRefToFloorPlan}
                                   isLoading={isLoading}
                                   floorplans={property.floorplans}/>
                </div>
                <AmenitiesSection/>
                <div className="container">
                    <h2 className="heading"><span className="emphasized">Images</span></h2>
                    <DriveGallery
                        propertyId={propertyId}
                        driveId={property.photosFolderId}
                        initialSize={9}
                        type="simple"
                        showName={false}
                    />
                </div>
                <div ref={floorPlanRef} id="floorplan" className="reference">
                </div>
                {isLoading ?
                    <FloorplanSectionSkeleton/> :
                    <>{property?.floorplans ?
                        <FloorplansSection propertyId={propertyId}
                                           floorplans={
                                               sortFloorplans(
                                                   property.floorplans.filter(floorplan => floorplan.active)
                                                       .map(floorplan => convertToFloorplanCardData(floorplan)
                                                       ), "featured")
                                           }/> : ""}

                    </>
                }
                <div ref={mapRef} id="location" className="reference">
                </div>
                <div className="container">
                    <h2 className="heading"><span className="emphasized">Location</span></h2>
                </div>
                <PropertyLocation property={property} isLoading={isLoading}
                                  handleRefToContact={() => {
                                      setShowContactModal(true);
                                      trackContactClicked("scholars-quad");
                                  }}/>

                <div className="container">
                    <TeamSection propertyId={propertyId} isLoading={isLoading} teamMembers={property.teamMembers}/>
                </div>

                <div className="container">
                    <hr/>
                </div>
            </MainLayout>
        </>
    );
};

export interface PageProps {
    isLoading: boolean;
    property: PropertyDetails;
    contact: Contact;
}
