import React from 'react';
import './assets/FeatureSection.scss';

export const FeatureSection: React.FC = () => {
    return (
        <div className="container main">
            <section className="custom-feature">
                <div className="custom-feature--detail">
                    <h2>Rise & Shine</h2>
                    <p> Wake up on the right side at Scholar's Quad. With spacious
                        rooms, airy windows, and easy campus access, you'll be well on
                        your way to graduation day.
                    </p>
                </div>
                <div className="custom-feature--detail">
                    <h2>Wise Up</h2>
                    <p>Get smart with your campus living. Scholar's Quad offers a quiet residence close to campus that
                        will help you keep your studies on track. We're located less than one mile to the library with
                        access to public transit right at our door (so no excuses!). </p>
                </div>
                <div className="custom-feature--detail">
                    <h2>Details Matter</h2>
                    <p>That's why every unit includes large windows to let in floods of light, cool stained concrete
                        floors, and a thoughtfully designed floor plan. And yes, your friends will be jealous. You've
                        been warned.
                    </p>
                </div>
            </section>
        </div>
    );
}