import React from 'react';
import './assets/HeroSection.scss';
import heroMobile from "./assets/Hero-mobile.jpg";
import hero from "./assets/Hero.jpg";
import {Button} from "@contentmunch/muncher-ui";

export const HeroSection: React.FC<HeroSectionProps> = ({handleRefToFloorPlan}) => {
    return (
        <div className="container">
            <section className="section-hero">
                <img src={heroMobile} alt="hero" className="mobile"/>
                <img src={hero} alt="hero" className="main"/>
                <div className="hero-content">
                    <h2>
                        wake up on the right side!
                    </h2>
                    <p><i>
                        Conveniently located 1 & 2 bedroom apartments that are close to
                        campus & have easy access to public transit</i>
                    </p>
                    <Button variant="secondary" onClick={handleRefToFloorPlan}>Find Your Apartment</Button>
                </div>
            </section>
        </div>
    );
}

export interface HeroSectionProps {
    handleRefToFloorPlan: () => void;
}