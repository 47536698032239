import React from 'react';
import {NavProps} from "./NavProps";
import {trackContactClicked} from "@renaissancerentals/renaissance-component";

export const HeaderNav: React.FC<HeaderNavProps> = (
    {
        setShowApplicationModal, setShowContactModal, modalClose, handleRefToFloorPlan, handleRefToMap, isMainPage,
        children
    }) => {

    return (
        <nav>
            <h3><a href={isMainPage ? "#floorplan" : "/#floorplan"} onClick={() => {
                if (handleRefToFloorPlan) handleRefToFloorPlan();
                modalClose()
            }}>floorplans</a></h3>
            <h3><a href={handleRefToMap ? "#location" : "/#location"} onClick={() => {
                if (handleRefToMap)
                    handleRefToMap();
                modalClose()
            }}>location</a></h3>
            {children}
            <h3><a href="#apply" onClick={() => {
                modalClose();
                setShowApplicationModal(true);

            }}>apply</a></h3>
            <h3><a href="#contact" onClick={() => {
                modalClose();
                setShowContactModal(true);
                trackContactClicked("scholars-quad");
            }}>contact</a></h3>
        </nav>
    )
};

export interface HeaderNavProps extends NavProps {
    modalClose: () => void;
}
