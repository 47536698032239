import React from 'react';
import './assets/Amenities.scss';

export const AmenitiesSection: React.FC = () => {

    return (
        <section className="section-amenities">
            <div className="container">
                <h2 className="heading"><span className="emphasized">Amenities</span></h2>
                <div className="section-amenities--content">
                    <div>
                        <h4>Our Community</h4>
                        <ul>
                            <li>&lt;1 mile to Main Library</li>
                            <li>Smoke-free Community</li>
                            <li>Free off-street parking</li>
                            <li>On Bloomington Transit 6 &amp; 9 bus routes</li>
                            <li>Large Open Grassy Courtyard</li>
                            <li>Community Grill &amp; Picnic Area</li>
                            <li>Controlled-Access Interior Mail room</li>
                            <li>Community Lounge with WiFi</li>
                            <li>On-site Maintenance</li>
                            <li>On-site Management</li>
                            <li>24 hour emergency maintenance</li>
                            <li>Managed by <a href="https://www.renaissancerentals.com">Renaissance Rentals</a>,
                                Bloomington's most trusted Local Landlord
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4>Our Apartments</h4>
                        <ul>
                            <li>Contemporary 1 &amp; 2 bedroom apartments</li>
                            <li> Washer &amp; Dryer Included</li>
                            <li>Electronic entry locks</li>
                            <li>Basic Broadband Internet service included</li>
                            <li>Stained Concrete floors in kitchen / dining nook</li>
                            <li>Berber carpet in living &amp; bedrooms</li>
                            <li>Modern Brushed Nickel finishes throughout</li>
                            <li>Ceiling fan in living &amp; bedrooms</li>
                            <li>Bedroom closets with organizational system</li>
                            <li>Large Double Pane Windows</li>
                            <li>1 bedroom apartments with built-in microwave</li>
                            <li>2 bedroom apartments with equal-sized bedrooms, 2 private baths, dishwasher &amp; all
                                utilities included
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};
