import React from 'react';
import './assets/Action.scss';
import magnifyIcon from "./assets/MagnifyingGlass.png";
import applyIcon from "./assets/Apply.png";
import calendarIcon from "./assets/Calendar.png";
import {FloorplanCardSkeleton, FloorplanCardSlider, FloorplanDetails} from "@renaissancerentals/renaissance-component";
import {propertyId} from "../../services/DataService";

export const ActionSection: React.FC<ActionSectionProps> = (
    {handleApplyClicked, handleContactClicked, handleRefToFloorPlan, floorplans, isLoading}) => {

    return (
        <section className="section-action">
            <div className="container">
                <div className="actions">
                    <div className="action">
                        <div className="action--icon">
                            <a href="#floorPlan" onClick={handleRefToFloorPlan}>
                                <img src={magnifyIcon} alt="search icon" height={40}/>
                            </a>
                        </div>
                        <div className="action-content">
                            <h3><a href="#floorPlan" onClick={handleRefToFloorPlan}>Find Your Scholar's Quad
                                Apartment</a></h3>
                            <p>Thoughtfully designed 1 & 2 bedroom apartments with airy windows and easy campus
                                access</p>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action--icon">
                            <a href="#contact" onClick={handleContactClicked}>
                                <img src={calendarIcon} alt="schedule icon" height={40}/>
                            </a>
                        </div>
                        <div className="action-content">
                            <h3><a href="#contact" onClick={handleContactClicked}>Schedule a Tour</a></h3>
                            <p>
                                Ask a question or schedule a tour, we’re here for you all week, every week.
                            </p>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action--icon">
                            <a href="#apply" onClick={handleApplyClicked}> <img src={applyIcon} alt="apply icon"
                                                                                height={40}/></a>
                        </div>
                        <div className="action-content">
                            <h3><a href="#apply" onClick={handleApplyClicked}>Apply Online &raquo;</a></h3>
                            <p>
                                It’s almost yours; just fill out the Free App and we’ll be in touch soon!
                            </p>
                        </div>
                    </div>

                </div>
                <div className="main">
                    {isLoading ? <FloorplanCardSkeleton/> :
                        <FloorplanCardSlider size="small" propertyId={propertyId}
                                             floorplans={floorplans.filter(floorplan => floorplan.active).filter(floorplan => floorplan.featured)}/>}

                </div>

            </div>
        </section>
    );
};

export interface ActionSectionProps {
    handleApplyClicked: () => void;
    handleContactClicked: () => void;
    handleRefToFloorPlan: () => void;
    floorplans: FloorplanDetails[];
    isLoading: boolean;
}

